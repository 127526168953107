// scroll bar

import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Loadable from "react-loadable";
import ContentSettingState from "./Hooks/ContentContext/ContentSettingState";

const injectGoogleKeys = () => {
  const googleTagKey = process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY; // Assuming you're using env for the key
  const googleSiteVerificationKey =
    process.env.REACT_APP_GOOGLE_SITE_VERIFICATION_KEY; // Assuming you're using env for this too

  // Inject Google Tag Manager Script
  let gtagScript = document.getElementById("gtag-script");

  // If the script tag doesn't exist, create it and append it to the head
  if (!gtagScript && googleTagKey) {
    gtagScript = document.createElement("script");
    gtagScript.id = "gtag-script";
    gtagScript.async = true;
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleTagKey}`;
    document.head.appendChild(gtagScript); // Append the script to the head
  } else if (gtagScript && googleTagKey) {
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleTagKey}`;
  }

  // Inject Google Site Verification Meta Tag
  let googleSiteVerificationMeta = document.getElementById(
    "google-site-verification"
  );

  // If the meta tag doesn't exist, create it and append it to the head
  if (!googleSiteVerificationMeta && googleSiteVerificationKey) {
    googleSiteVerificationMeta = document.createElement("meta");
    googleSiteVerificationMeta.id = "google-site-verification";
    googleSiteVerificationMeta.name = "google-site-verification";
    googleSiteVerificationMeta.content = googleSiteVerificationKey;
    document.head.appendChild(googleSiteVerificationMeta);
  } else if (googleSiteVerificationMeta && googleSiteVerificationKey) {
    googleSiteVerificationMeta.content = googleSiteVerificationKey;
  }

  // Initialize Google Analytics if the key exists
  if (googleTagKey) {
    gtagScript.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", googleTagKey, { debug_mode: true });
      window.gtag = gtag; // Assign gtag to window explicitly
    };
  }
};

//
const App = Loadable({
  loader: () => import(/*webpackChunkName: "rcs_App"*/ "./App"),
  loading() {
    return (
      <></>
      // <CircularProgress
      //   sx={{ marginLeft: "50%", marginTop: "20%" }}
      //   color="primary"
      // />
    );
  },
});

const RootComponent = () => {
  useEffect(() => {
    injectGoogleKeys();
  }, []);

  return <App />;
};

import * as serviceWorker from "./serviceWorker";
import reportWebVitals from "./reportWebVitals";
import { useEffect } from "react";
// import "bootstrap/dist/css/bootstrap.min.css";

// ----------------------------------------------------------------------

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <ContentSettingState>
        <RootComponent />
      </ContentSettingState>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById("root")
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
